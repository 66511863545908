import { Override } from "framer"
import * as React from "react"

// Shared state to track if the video is playing
const useVideoState = () => {
    const [isPlaying, setIsPlaying] = React.useState(false)
    return { isPlaying, setIsPlaying }
}

// Override to control Slideshow autoPlay
export function SlideshowOverride(): Override {
    const { isPlaying } = useVideoState()

    return {
        autoPlay: !isPlaying, // Pause Slideshow when video is playing
    }
}

// Override to handle video play/pause
export function VideoOverride(): Override {
    const { setIsPlaying } = useVideoState()

    const handleMessage = (event) => {
        if (event.data === "play") setIsPlaying(true)
        if (event.data === "pause") setIsPlaying(false)
    }

    return {
        onMessage: handleMessage, // Listen for play/pause messages from the embedded video
    }
}
